.dashboardGrid {
    display: grid;
    grid-template-areas:
      "sidebar top-metrics top-metrics"
      "sidebar main-content right-sidebar"
      "sidebar bottom-section bottom-section";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
    height: 100vh;
    gap: 10px;
  }
  
  .container {
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
  }
  
  #sidebar {
    grid-area: sidebar;
    border: 2px solid rgb(248, 246, 246);
  }
  
  #top-metrics {
    grid-area: top-metrics;
    border: 2px solid rgb(245, 245, 248);
  }
  
  #main-content {
    grid-area: main-content;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
  }
  
  #right-sidebar {
    grid-area: right-sidebar;
    border: 2px solid rgb(247, 247, 243);
  }
  
  #bottom-section {
    grid-area: bottom-section;
    border: 2px solid rgb(245, 245, 248);
  }
  