.card1 {
  background-color: rgb(210, 214, 230);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cardCreate {
  margin-bottom: 10px;
}

.createButton {
  background-color: #4caf50;
  width: 70%; /* Ensure the search box occupies 40% of the container's width */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.searchBox {
  width: 40%; /* Ensure the search box occupies 40% of the container's width */
  max-width: 100%; /* Ensure it does not exceed the container's width */
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cardNetworks {
  overflow-y: scroll;
  max-height: 400px;
}

.networkItem {
  margin-bottom: 10px;
}

.networkButton {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  width: 80%; /* Ensure the button occupies the full width of the container */
  text-align: left;
}