.mainContent {
  display: flex;
  flex-direction: column; /* Stacks Card1 on top of Card2 */
  height: calc(100vh - 80px); /* Adjust height to fill the screen minus header */
  box-sizing: border-box;
  padding: 20px;
  border: 2px solid rgb(250, 250, 250); /* Red border for main content */
  gap: 15px; /* Space between Card1 and Card2 */
}

.card1Container {
  flex: 1; /* Card1 takes up available space */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card2Container {
  flex: 2; /* Card2 takes up more space than Card1 */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
