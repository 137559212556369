.card1 {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

.singleRow {
  display: flex;
  align-items: center;
  gap: 10px; /* Minimal spacing between elements */
  justify-content: space-between;
  width: 100%; /* Ensure it fills the container width */
}

.singleRow > div {
  flex: 1; /* Make all items flexible */
  margin: 0; /* Remove margins between elements */
}

.templateChooser {
  flex: 2; /* The main input gets more space */
}

.singleRow input,
.singleRow select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%; /* Fill the parent flex item */
  box-sizing: border-box; /* Include padding and borders in width calculation */
}

.saveButton {
  flex: 0; /* Prevent the button from stretching */
  width: auto; /* Let the button size itself naturally */
  min-width: 120px; /* Ensure a consistent minimum size */
  padding: 10px 20px; /* Add comfortable padding */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  margin-top: 10px; /* Create small space above the button */
  align-self: center; /* Center the button horizontally */
}

.saveButton:hover {
  background-color: #0056b3;
}

/* Responsive Adjustment */
@media (max-width: 768px) {
  .saveButton {
    width: 100%; /* Full width on small screens */
    min-width: unset; /* Remove minimum width constraint */
  }
}



/* Adjust padding for small screens */
@media (max-width: 768px) {
  .singleRow {
    flex-direction: column; /* Stack items vertically on smaller screens */
    gap: 10px;
  }

  .templateChooser,
  .singleRow input,
  .singleRow select,
  .saveButton {
    width: 100%; /* Full width for each item */
    flex: unset; /* Remove flex behavior */
  }
}
