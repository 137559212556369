.page-container {
  display: flex;
  height: 100vh;
  position: relative;
}

.card-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container-1 {
  background-color: white;
}

.card-container-2 {
  background-color: #4b2e2e; /* Dark brown color */
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black; /* Black border */
}

.login-logo img {
  width: 100px;
  margin-bottom: 20px;
}

.login-form h2 {
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.input-group svg {
  position: absolute;
  left: 10px;
  color: #999;
}

.input-group input {
  width: calc(100% - 40px); /* Make input field take up the remaining space considering icon and padding */
  padding: 10px 10px 10px 40px; /* Adjust padding to match the login form */
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input-group input:focus {
  border-color: #007bff;
}

.login-form button {
  padding: 10px 20px;
  background: #007bff; /* Match button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Make button full width */
  font-size: 16px;
  transition: background-color 0.3s;
}

.login-form button:hover {
  background: #0056b3; /* Match hover color */
}

.login-footer {
  margin-top: 20px;
  text-align: center;
}

.login-footer a {
  color: #007bff;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
