.card1 {
  border: 2px solid rgb(255, 255, 255);
  padding: 20px;
  background-color: #deddec;
  box-sizing: border-box;
  width: 100%;
}

.sectionButtons {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: #d6d5e2;
}

.sectionButton {
  background-color: #0c67df;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #d6d8eb;
  flex-grow: 1;
  text-align: center;
}

.sectionButton.active {
  background-color: #ff0062;
  color: white;
}

.sectionContent {
  margin-top: 10px;
  background-color: #d6d5e2;
}

.uploadSection,
.deleteRowsSection {
  padding: 20px;
  border: 1px solid #ffffff;
  background-color: #d6d5e2;
}

.uploadSection .dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
}

.deleteRowsSection label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.deleteRowsSection input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.deleteRowsSection .deleteButton {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

/* Feedback message styling */
.feedback {
  font-size: 1rem;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
}

.feedback.green {
  background-color: #28a745;
}

.feedback.red {
  background-color: #dc3545;
}
