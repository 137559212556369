/* Layout Container */
.proxySettingsContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
  box-sizing: border-box; /* Include padding in width calculations */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Sidebar */
.sidebar {
  grid-area: sidebar;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Main Content */
.mainContent {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure content does not exceed the parent container */
  box-sizing: border-box; /* Include padding in width calculations */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Heading */
.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Card Container */
.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Ensure the container fits within the parent */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Card1 Styling */
.card1 {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Prevent card from exceeding the container width */
  box-sizing: border-box; /* Include padding and border in width calculations */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Card2 Styling */
.card2 {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Prevent card from exceeding the container width */
  box-sizing: border-box; /* Include padding and border in width calculations */
  overflow-x: auto; /* Allow horizontal scrolling for table overflow */
}

/* Table Styling for Card2 */
.card2 .table {
  width: 100%; /* Ensure the table fits within the card */
  table-layout: fixed; /* Prevent table from stretching beyond container */
  border-collapse: collapse;
}

.card2 .table th, .card2 .table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: anywhere; /* Handle long text or URLs gracefully */
}

.card2 .table th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.card2 .table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.card2 .table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .proxySettingsContainer {
    grid-template-columns: 1fr; /* Stack Sidebar and Content vertically */
    gap: 15px;
  }

  .mainContent {
    padding: 15px;
  }

  .cardContainer {
    gap: 15px;
  }
}
