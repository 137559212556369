.card2 {
  background-color: rgb(210, 214, 230);
  border: 1px solid #fffbfb;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 93%;
}

.card2 h2 {
  margin-bottom: 20px;
  text-align: center;
}

.card2 form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.formGroup {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input,
.formGroup select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.createButton {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
  width: 80%;
}

.createButton:hover {
  background: #0056b3;
}

.deleteButton {
  padding: 10px 20px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
  width: 80%;
  margin-top: 10px; /* Add some margin to separate from other buttons */
}

.deleteButton:hover {
  background: #c82333;
}

.formActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column {
  width: 48%; /* Adjust as necessary to fit your design */
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.formActions {
  display: flex;
  justify-content: space-between;
}

.createButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.saveButton {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.deleteButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}