.card2 {
  border: 2px solid #ddd;
  padding: 20px;
  overflow-x: auto; /* Horizontal scrolling if necessary */
  width: 100%;
  max-width: 100%;
}

.tableContainer {
  max-height: 400px; /* Set the maximum height of the table container */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling unless necessary */
}

.rowsTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.rowsTable th, .rowsTable td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.rowsTable th {
  background-color: #f2f2f2;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  position: sticky;
  top: 0; /* Keep the table header fixed when scrolling */
  z-index: 1; /* Ensure it stays on top of the rows */
}

.rowsTable td {
  background-color: #fff;
  vertical-align: middle;
}

.rowsTable tr:hover {
  background-color: #f5f5f5;
}

.feedback {
  margin-bottom: 20px;
  color: #28a745; /* Success color */
  font-weight: bold;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.deleteButton:hover {
  background-color: #c82333;
}

/* Highlight selected rows */
.rowsTable tr.selectedRow {
  background-color: #e9ecef;
}

.checkboxColumn {
  width: 50px; /* Adjust the width of the checkbox column */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .rowsTable th, .rowsTable td {
    padding: 8px;
    font-size: 12px;
  }

  .deleteButton {
    padding: 8px 10px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pageButton:hover:not(.activePageButton) {
  background-color: #f1f1f1;
  color: #000;
}

.activePageButton {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0056b3;
}
