.networksContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background: #f8f9fc;
  padding: 20px;
}

.networksMain {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.networksHeading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.settingsCard {
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.templateButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.templateButton {
  padding: 10px 15px;
  background-color: #f8f9fc;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.templateButton.active {
  background-color: #003366;
  color: white;
}

.templateButton:hover {
  background-color: #002244;
  color: white;
}
