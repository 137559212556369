.topMetrics {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateTime {
  font-size: 18px;
}

.buttonCard {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.filterButton {
  margin: 5px;
  padding: 10px 40px; /* Make buttons wider */
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.filterButton.active {
  background-color: #0056b3;
}

.dropdownCard {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dropdown, .customDropdown {
  margin-right: 10px;
  position: relative;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  width: 200px; /* Make the dropdown button wider */
  text-align: left;
}

.columnsDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  width: 200px; /* Make dropdown wider */
}

.columnOption {
  display: block;
  margin-bottom: 5px;
}

.applyButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
