.mainContent {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between Card1 and Card2 */
  padding: 20px;
  background-color: #f8f9fc; /* Light page background */
  border-radius: 8px;
  box-shadow: none; /* No shadow on mainContent wrapper */
}

.card1Container,
.card2Container {
  width: 100%; /* Full width within the parent container */
  padding: 20px;
  background-color: #ffffff; /* White background for individual cards */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Consistent shadow styling */
}
