.card2 {
  background-color: #f4f5fa; /* Light gray background */
  border: 1px solid #ddd; /* Neutral border */
  border-radius: 8px; /* Smooth rounded corners */
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 93%;
  margin: 20px auto;
}

.card2 h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50; /* Neutral dark color */
}

/* Form Layout */
.card2 form {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 20px; /* Spacing between columns and rows */
  width: 100%;
}

/* Form Group Styles */
.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #2c3e50; /* Neutral dark color for labels */
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px; /* Rounded corners */
  font-size: 14px;
  background-color: #ffffff; /* White background */
  width: 100%; /* Full width within grid */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle inner shadow */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

textarea {
  resize: none; /* Disable resizing */
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  border-color: #27ae60; /* Green border on focus */
  box-shadow: 0 0 5px rgba(39, 174, 96, 0.5); /* Subtle glow */
  outline: none; /* Remove default outline */
}

/* Template Buttons */
.templateButtons {
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fc; /* Light background for the button container */
  border: 1px solid #ddd; /* Border around the container */
  border-radius: 4px; /* Rounded edges for the container */
  overflow: hidden; /* Ensures rounded corners apply to buttons */
  margin-bottom: 20px;
}

.templateButton {
  flex: 1;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #34495e; /* Neutral dark text color */
  background-color: #f8f9fc; /* Matches container background */
  border-left: 1px solid #ddd; /* Separating line between buttons */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
}

.templateButton:first-child {
  border-left: none; /* No border for the first button */
}

.templateButton.active {
  background-color: #27ae60; /* Green for active state */
  color: white;
}

.templateButton:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

/* Action Button Styles */
.createButton,
.saveButton,
.deleteButton {
  padding: 14px 30px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  background: linear-gradient(135deg, #4caf50, #3e8e41); /* Gradient green */
  border-radius: 8px; /* Slightly more rounded */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Create Button Styling */
.createButton {
  padding: 14px;
  border: none;
  color: #34495e; /* Neutral dark text color */
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #f8f9fc; /* Light gray matching the template buttons */
  border: 1px solid #ddd; /* Neutral border */
  border-radius: 4px; /* Rounded edges */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  width: 40%; /* Make the button wider */
  margin: 20px auto; /* Center the button */
  display: block; /* Center alignment in block layout */
}

.createButton:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
  transform: translateY(-2px); /* Slight lift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.createButton:active {
  background-color: #d5d5d5; /* Darker gray on click */
  transform: scale(0.98); /* Slight shrink effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
}


.saveButton:hover {
  background-color: #1976d2; /* Darker blue */
  transform: scale(1.05);
}

.deleteButton {
  background-color: #f44336; /* Red */
}

.deleteButton:hover {
  background-color: #d32f2f; /* Darker red */
  transform: scale(1.05);
}

/* Button Layout */
.formActions {
  grid-column: span 2; /* Span both columns */
  display: flex;
  justify-content: center; /* Center-align buttons */
  gap: 15px; /* Space between buttons */
  margin-top: 20px;
}

/* Success and Error Messages */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

.success {
  background-color: #d4edda; /* Light green for success */
  color: #155724; /* Dark green text */
}

.error {
  background-color: #f8d7da; /* Light red for error */
  color: #721c24; /* Dark red text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .card2 form {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .formActions {
    justify-content: center;
  }

  .createButton {
    width: 100%; /* Full width for smaller screens */
  }
}

@media (max-width: 480px) {
  .templateButton {
    width: 100%; /* One button per row for very small screens */
  }
}
