/* Container Layout */
.scheduledTasksContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
}

/* Sidebar */
.sidebar {
  grid-area: sidebar;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main Content */
.mainContent {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Buttons */
.buttonContainer {
  margin-bottom: 20px;
}

.taskButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.taskButton:hover:not(:disabled) {
  background-color: #0056b3;
}

.taskButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pauseButton {
  background-color: #ffc107;
  color: black;
}

.pauseButton:hover:not(:disabled) {
  background-color: #e0a800;
}

/* Task Table */
.taskTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.taskTable th,
.taskTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.taskTable th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.taskTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.taskTable tr:hover {
  background-color: #f1f1f1;
}

.noTasksMessage {
  text-align: center;
  color: #999;
  margin-top: 20px;
}

.selectedRow {
  background-color: #c6f6d5; /* Light green highlight */
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff; /* Ensure background is white */
  color: #333; /* Ensure text color is clearly visible */
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pageButton:hover:not(.activePageButton) {
  background-color: #f1f1f1; /* Slightly darker hover background */
  color: #000; /* Make text stand out on hover */
}

.activePageButton {
  background-color: #007bff; /* Highlighted background for active page */
  color: #fff; /* White text for active page */
  border: 1px solid #0056b3; /* Slightly darker border for active page */
}

