/* Container Layout */
.userAgentSettingsContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
}

/* Sidebar */
.sidebar {
  grid-area: sidebar;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main Content */
.mainContent {
  grid-area: content;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Card Container */
.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Card1 and Card2 Styles */
.card1, .card2 {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Pagination */
.pagination {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center the pagination buttons */
  gap: 10px; /* Space between buttons */
  margin-top: 20px;
  max-width: 100%; /* Ensure pagination stays within the container width */
  overflow-x: auto; /* Add horizontal scrolling for smaller screens if needed */
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  flex: 0 0 auto; /* Prevent buttons from shrinking or stretching unevenly */
}

.pageButton:hover:not(.activePageButton) {
  background-color: #f1f1f1;
  color: #000;
}

.activePageButton {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0056b3;
}

.taskTable {
  width: 100%; /* Make the table span the full container width */
  border-collapse: collapse; /* Combine adjacent borders */
  margin-top: 20px; /* Space above the table */
  table-layout: fixed; /* Enforce defined column widths */
}

.taskTable th,
.taskTable td {
  border: 1px solid #ddd; /* Add gridlines */
  padding: 10px; /* Padding for readability */
  text-align: left; /* Align content to the left */
  white-space: pre-wrap; /* Allow wrapping of text while preserving spaces */
}

.taskTable th {
  background-color: #f9f9f9; /* Light gray background for headers */
  font-weight: bold; /* Bold text for headers */
}

.taskTable td {
  background-color: #fff; /* White background for table cells */
}

.taskTable tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row colors */
}

.taskTable tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

/* Define column widths */
.taskTable th:nth-child(1), 
.taskTable td:nth-child(1) {
  width: 60%; /* Largest width for "User Agent" */
}

.taskTable th:nth-child(2), 
.taskTable td:nth-child(2) {
  width: 20%; /* Medium width for "Used" */
}

.taskTable th:nth-child(3), 
.taskTable td:nth-child(3) {
  width: 20%; /* Medium width for "Details" */
}

/* Style for the "Details" button */
.detailsButton {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.detailsButton:hover {
  background-color: #0056b3;
}


/* Modal Close Button */
.closeModalButton {
  background-color: #ff4d4d;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.closeModalButton:hover {
  background-color: #cc0000;
}
