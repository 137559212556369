.scheduleContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
}

.scheduleMain {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.scheduleHeading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.scheduleContent {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
