/* General Dashboard Layout */
.dashboard-container {
  display: grid;
  grid-template-areas:
    "sidebar content";
  grid-template-columns: 280px 1fr; /* Sidebar slightly wider for consistent layout */
  gap: 30px; /* Add more spacing between sidebar and main content */
  min-height: 100vh;
  background: #f8f9fc;
  box-sizing: border-box;
  padding: 20px; /* Add spacing around the container */
}

/* Sidebar */
#dashboard-sidebar {
  grid-area: sidebar;
  background-color: #003366;
  padding: 15px;
  color: #ffffff;
  height: 100%; /* Full height for the sidebar */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items at the top */
  position: sticky;
  top: 0; /* Keeps sidebar fixed during scrolling */
  overflow-y: auto; /* Makes it scrollable if content overflows */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners for sidebar */
  margin-right: 20px; /* Spacing between sidebar and main content */
}

/* Main Content */
.dashboard-main {
  grid-area: content;
  flex: 1;
  padding: 25px; /* Increase padding for better spacing */
  background: #ffffff; /* Main content background */
  box-sizing: border-box;
  border: 1px solid #ddd; /* Add a subtle border around main content */
  border-radius: 8px; /* Rounded corners for main content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Dashboard Heading */
.dashboard-heading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
}

/* Card Container */
.settings-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Card shadow */
  padding: 25px;
  margin-bottom: 20px;
}

/* Individual Card Section */
.settings-card-section {
  padding: 20px;
  border: 1px solid #ddd; /* Card border */
  border-radius: 10px;
  background-color: #fafafa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for card sections */
  transition: box-shadow 0.3s ease; /* Animation on hover */
}

.settings-card-section:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.settings-card-section h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #34495e;
}

/* Grid for Items */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive card grid */
  gap: 20px; /* Spacing between grid items */
}

/* Individual Grid Items */
.settings-card-item {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.settings-card-item:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.settings-card-item h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c3e50;
}

.settings-card-item p {
  font-size: 16px;
  color: #7f8c8d;
}

/* Task-Specific Colors */
.in-progress {
  background-color: #ffeaa7; /* Light Yellow */
}

.pending {
  background-color: #fab1a0; /* Light Red */
}

.completed {
  background-color: #81ecec; /* Light Blue */
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  justify-content: space-between;
  gap: 0; /* Remove spacing between buttons */
  margin-top: 10px;
}

.filter-buttons button {
  flex: 1; /* Make buttons take equal width */
  padding: 10px 0;
  font-size: 14px;
  border: none;
  border-left: 1px solid #ccc; /* Line separating buttons */
  background: #f8f9fc;
  color: #34495e;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-buttons button:first-child {
  border-left: none; /* Remove left border from the first button */
}

.filter-buttons button:hover {
  background: #e0e0e0; /* Light gray background on hover */
  transform: scale(1.03); /* Slight scale effect on hover */
}

.filter-buttons button.active {
  background: #27ae60; /* Green background for active state */
  color: #fff;
  border-color: #27ae60;
}

/* Charts Section */
.charts-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid for charts */
  gap: 20px; /* Spacing between charts */
  margin-top: 20px;
}

.chart-container {
  display: flex;
  justify-content: center; /* Center chart horizontally */
  align-items: center; /* Center chart vertically */
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for charts */
  height: 400px; /* Consistent height for all charts */
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-container {
    grid-template-areas:
      "sidebar"
      "content";
    grid-template-columns: 1fr; /* Sidebar stacks on top */
    padding: 10px; /* Adjust spacing on smaller screens */
    gap: 15px; /* Reduce gap on smaller screens */
  }

  #dashboard-sidebar {
    height: auto; /* Allow sidebar to expand */
    margin-right: 0; /* Remove right margin when stacked */
  }

  .grid {
    grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
  }

  .filter-buttons button {
    font-size: 12px;
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .metric-card,
  .task-card,
  .chart-card,
  .activity-card {
    font-size: 14px;
    padding: 15px;
  }
}
