.bottom-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f5f5f5;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto; /* Adjust to fit content */
  margin: 0; /* Ensure no extra margin */
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Adjust max width as needed */
}

.footer-buttons {
  display: flex;
}

.footer-button {
  background: none;
  border: none;
  color: blue;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
}

.footer-button:hover {
  text-decoration: underline;
}

.footer-text {
  font-size: 14px;
  color: #666;
  margin-left: auto;
  padding-left: 20px; /* Optional: add some padding for spacing */
}
