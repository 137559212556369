/* General Layout */
.crmContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background: #f8f9fc;
  padding: 20px;
}

.crmMain {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.crmHeading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Card Container */
.settingsCard {
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.settingsCardSection {
  margin-bottom: 20px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fc;
  cursor: pointer;
}

.button.active {
  background-color: #27ae60;
  color: white;
}

/* Table Styling */
.crmTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.crmTable th,
.crmTable td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.crmTable th {
  background-color: #003366;
  color: white;
}

.noData {
  text-align: center;
  color: #7f8c8d;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #f8f9fc;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button.active {
  background-color: #27ae60;
  color: white;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
