.topMetrics {
  padding: 20px;
  background: #f1f1f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateTime {
  font-size: 16px;
  color: #333;
  text-align: right;
}

.buttonCard {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background: #f1f1f1;
  padding: 1px;
}

.filterButton {
  background: #e6f7ff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  color: black;
  transition: background-color 0.3s;
}

.filterButton:hover,
.filterButton.active {
  background: darkblue;
  color: white;
}

.dropdownCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  background: #e6f7ff;
  padding: 10px;
  border-radius: 4px;
}

.dropdown {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(40% / 3 - 10px);
}

.applyButton {
  background: blue;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.applyButton:hover {
  background: lightblue;
  color: white;
}
