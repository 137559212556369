/* Container Layout */
.taskResultContainer {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 280px 1fr;
  gap: 30px;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
}

/* Sidebar */
.sidebar {
  grid-area: sidebar;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main Content */
.mainContent {
  grid-area: content;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Message */
.message {
  text-align: center;
  font-size: 18px;
  color: #555;
  margin-top: 20px;
}

/* Task Table */
.taskTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.taskTable th,
.taskTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.taskTable th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.taskTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.taskTable tr:hover {
  background-color: #f1f1f1;
}

.viewResultsButton {
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewResultsButton:hover {
  background-color: #0056b3;
}

/* Task Details */
.taskDetails {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.taskDetails h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

/* Results Table */
.resultsTable {
  width: 100%;
  border-collapse: collapse;
}

.resultsTable th,
.resultsTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.resultsTable th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.resultsTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Collapsible Details for URLs */
details {
  margin-top: 10px;
  cursor: pointer;
}

details summary {
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

details ul {
  margin-top: 10px;
  padding-left: 20px;
}

details ul li {
  list-style-type: disc;
  margin-bottom: 5px;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pageButton:hover:not(:disabled) {
  background-color: #f1f1f1;
}

.pageButton:disabled {
  background-color: #e9ecef;
  color: #bbb;
  cursor: not-allowed;
}

.activePageButton {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0056b3;
}

/* Prevent long URLs from stretching the page */
.resultsTable td {
  max-width: 400px; /* Limit the maximum width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Truncate long text with ellipsis */
}

/* Enable wrapping for long URLs (optional alternative) */
.resultsTable td.wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

/* Links inside the table */
.resultsTable a {
  color: #007bff;
  text-decoration: none;
}

.resultsTable a:hover {
  text-decoration: underline;
}


/* Sortable Column */
.sortableColumn {
  cursor: pointer;
  user-select: none;
}

.sortableColumn:hover {
  color: #007bff;
  text-decoration: underline;
}

/* Active Sort Indicator */
.sortableColumn:after {
  content: '';
}
