.mainContent {
  display: flex;
  height: calc(100vh - 80px); /* Adjust height to fill the screen minus header */
  box-sizing: border-box;
  padding: 20px;
  border: 2px solid rgb(255, 255, 255); /* Red border for main content */
}

.cardContainer {
  margin: 10px;
  padding: 20px;
  border: 2px solid rgb(226, 224, 230); /* Blue border for card container */
  border-radius: 7px;
  overflow-y: auto;
}

.card1Container {
  flex: 1;
  max-width: 20%; /* Card 1 takes up 50% of the width */
}

.card2Container {
  flex: 1;
}
