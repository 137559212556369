.card2 {
  border: 1px solid #ddd; /* Align with the overall border style */
  padding: 20px;
  background-color: #f9f9f9; /* Align with Card1 */
  box-sizing: border-box;
  border-radius: 8px; /* Consistent with Card1 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px; /* Add spacing if needed */
  width: 100%;
}

.dropzone {
  border: 2px dashed #cccccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.tableContainer {
  overflow-x: auto;
  max-height: calc(50px * 10); /* Scrollable if table exceeds 10 rows */
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures that columns take up specified widths */
}

.table th,
.table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.table th {
  position: sticky;
  top: 0;
  background: #f1f1f1; /* Background color for the header */
  z-index: 1; /* Ensure the header is above the body */
}

.table th:nth-child(1),
.table td:nth-child(1) {
  width: 50px; /* Checkbox column width */
}

.table th:nth-child(6),
.table td:nth-child(6) {
  width: 100px; /* Country Code column width */
}

.table th:nth-child(4),
.table td:nth-child(4) {
  width: 200px; /* Email column width */
}

.viewButton {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: green;
  color: white;
  cursor: pointer;
}

.viewButton:hover {
  background-color: darkgreen; /* Darken button on hover */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above all other elements */
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 490px;
  text-align: center;
  z-index: 1001; /* Ensure modal content is above the table */
}

.modalContent h3 {
  margin-bottom: 20px;
}

.modalContent p {
  margin-bottom: 10px;
}

.emptyMessage {
  text-align: center;
  color: gray;
}
