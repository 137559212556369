.card1 {
  border: 1px solid #ddd; /* Align with the overall border style */
  padding: 20px;
  background-color: #f9f9f9; /* Slightly lighter background for distinction */
  box-sizing: border-box;
  border-radius: 8px; /* Consistent with other cards */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px; /* Add spacing if needed */
}


.sectionButtons {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: #d6d5e2;
}

.sectionButton {
  background-color: #0c67df;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #d6d8eb;
  flex-grow: 1;
  text-align: center;
}

.sectionButton.active {
  background-color: #ff0062;
  color: white;
}

.sectionContent {
  padding: 20px;
  border: 1px solid #ffffff;
  margin-top: 10px;
  background-color: #d6d5e2;
}

.uploadSection .dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
}

.offerCountrySection label,
.proxySettingsSection label,
.userAgentSection label,
.scheduleTimeSection label,
.deleteRowsSection label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.offerCountrySection select,
.offerCountrySection input,
.proxySettingsSection input,
.userAgentSection input,
.scheduleTimeSection input,
.deleteRowsSection input {
  width: 100%; /* Adjusts the width of the input field */
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.deleteRowsSection .deleteButton {
  width: 10%; /* Adjusts the width of the button */
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}


.rowRange {
  display: flex;
  gap: 10px;
}

.rangeInputs select {
  width: 48%;
}

.twoColumn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.twoColumn div {
  flex: 1;
}

.assignedRowsSection ul {
  padding-left: 20px;
}

.assignedRowsSection ul li {
  list-style-type: disc;
}

.scheduledTasksSection button,
.logPanelSection button,
.actionButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.actionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.deleteRowsSection {
  display: flex;
  flex-direction: column;
}

.deleteRowsSection label {
  margin-bottom: 5px;
}

.deleteRowsSection input {
  margin-bottom: 10px;
}

.deleteRowsSection .deleteButton {
  margin-top: 10px;
}

/* New feedback message styles */
.feedback {
  margin-bottom: 10px;
  padding: 10px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
}

/* Specific changes for form components */
.offerCountrySection .twoColumn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.offerCountrySection .twoColumn div {
  flex: 1;
}

.offerCountrySection .rowRange .rangeInputs select {
  width: 48%;
}

.proxySettingsSection .twoColumn {
  display: flex;
  gap: 10px;
}

.proxySettingsSection .twoColumn div {
  flex: 1;
}

.proxySettingsSection .enableButton {
  margin-top: 10px;
}

.scheduleTimeSection .twoColumn {
  display: flex;
  gap: 20px;
}

.scheduleTimeSection .twoColumn div {
  flex: 1;
}

.scheduledTasksSection button {
  margin-bottom: 5px;
}

.logPanelSection button {
  margin-bottom: 5px;
}

/* Feedback message styling */
.feedback {
  font-size: 1rem;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
}

.feedback.green {
  background-color: #28a745;
}

.feedback.red {
  background-color: #dc3545;
}
