.leadsList {
    width: 100%;
    margin-top: 20px;
  }
  
  .leadsList table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leadsList th,
  .leadsList td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #f0dfdf;
  }
  
  .leadsList th {
    background-color: #3e2fca;
    color: white; /* White text color for better readability */

  }
  
  .leadsList tr:hover {
    background-color: #f1f1f1;
  }
  