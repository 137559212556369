/* Basic styling for the home page */
.home-page {
    font-family: Arial, sans-serif;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .home-header {
    background-color: #f8f9fa;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .auth-buttons {
    display: flex;
    gap: 10px;
  }
  
  .login-button, .register-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .login-button:hover, .register-button:hover {
    background-color: #0056b3;
  }
  
  .home-main {
    padding: 50px 20px;
  }
  
  .intro-section, .features-section {
    margin-bottom: 40px;
  }
  
  .features-section ul {
    list-style: none;
    padding: 0;
  }
  
  .home-footer {
    background-color: #f8f9fa;
    padding: 20px;
    border-top: 1px solid #ddd;
  }
  