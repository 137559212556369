.sidebar-container {
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sidebar {
  background-color: white;
  border: 2px solid black;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px; /* Rounded corners for the sidebar */
}

/* Menu Buttons */
.menu-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  background-color: #f4f4f4; /* Light gray background */
  border: 1px solid #ddd;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

.menu-button:hover {
  background-color: #0078d4;
  color: white;
}

.active {
  background-color: #0078d4;
  color: white;
}

/* Submenu */
.submenu {
  overflow: hidden;
  max-height: 0; /* Hidden by default */
  transition: max-height 0.3s ease-out; /* Smooth expansion */
  padding-left: 0; /* Remove extra padding */
  background-color: #f4f4f4; /* Submenu background matches menu button */
  border-radius: 10px; /* Rounded corners for submenu */
  margin: 5px 0;
  padding: 10px 15px;
  box-shadow: none; /* Ensure no shadow effect */
}

.submenu.open {
  max-height: 300px; /* Adjust based on content */
}

.submenu-button {
  display: block;
  padding: 8px 10px;
  margin-bottom: 5px;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  background-color: #e8e8e8; /* Subtle background for submenu buttons */
  border-radius: 8px; /* Rounded corners for submenu buttons */
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.submenu-button:hover {
  background-color: #d9d9d9; /* Darker gray on hover */
  text-decoration: none; /* Remove underline */
}

/* Profile Section */
.user-profile-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px; /* Rounded corners for profile section */
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
}

.user-icon {
  font-size: 36px;
  margin-bottom: 10px;
}

.user-details p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Logout Button */
.logout-card {
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px; /* Rounded corners for logout card */
  margin-bottom: 0;
}

.logout-button {
  background-color: white;
  color: #d9534f;
  border: 2px solid #d9534f;
  padding: 12px 20px;
  border-radius: 10px; /* Rounded corners for logout button */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: #d9534f;
  color: white;
}
